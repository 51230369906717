// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   fetchTicketsForAgent,
//   fetchTicketsForEmployee,
//   fetchCompanyTickets,
//   fetchChildCompanyTicketsById,
// } from "../config/webclient";
// import { MANAGER_TICKETS } from "../config/api";
// import { showToast } from "../components/global/showToast";
// import { MANAGER_COUNT,CHILD_COMPANY_COUNT,AGENT_COUNT,EMPLOYEE_COUNT,COMPANY_COUNT } from "../config/api";

// export const fetchTicketsForRole = createAsyncThunk(
//   "tickets/fetchTicketsForRole",
//   async ({ id, role }, { rejectWithValue }) => {
//     try {
//       if (role === "user") {
//         const response = await fetchTicketsForEmployee(id);
//         if (!response.data.error) {
//           return response.data.meta.tickets;
//         } else if (response.data.error) {
//           showToast("error", response.data.message);
//         }
//       } else if (["agent", "manager", "employee"].includes(role)) {
//         try {
//           // Initialize empty arrays for tickets
//           let agentTickets = [];
//           let managerTickets = [];

//           // Fetch agent tickets
//           try {
//             const agentTicketsResponse = await fetchTicketsForAgent(id);
//             agentTickets = agentTicketsResponse?.data?.meta?.tickets || [];

//           } catch (error) {
//             console.warn("Error fetching agent tickets:", error.message);
//           }

//           // Fetch manager tickets
//           try {
//             const managerTicketResponse = await fetch(`${MANAGER_TICKETS}/${id}`);
//             if (managerTicketResponse.ok) {
//               const managerTicketsData = await managerTicketResponse.json();
//               managerTickets = managerTicketsData?.meta?.tickets || [];

//             } else if (managerTicketResponse.status === 404) {
//               console.warn("Manager tickets not found (404).");
//             } else {
//               throw new Error(`Error fetching manager tickets: ${managerTicketResponse.statusText}`);
//             }
//           } catch (error) {
//             console.warn("Error fetching manager tickets:", error.message);
//           }

//           // Combine and deduplicate tickets
//           const combinedTickets = [...agentTickets, ...managerTickets];
//           const uniqueTickets = combinedTickets.reduce((acc, current) => {
//             if (!acc.some((item) => item.id === current.id)) {
//               acc.push(current);
//             }
//             return acc;
//           }, []);

//           return uniqueTickets;
//         } catch (error) {
//           console.error("Error in ticket fetching process:", error);
//           throw error; // Re-throw for upper-level handling
//         }

//       } else if (role === "child") {
//         const response = await fetchChildCompanyTicketsById(id);

//         if (!response.data.error) {
//           return response.data.meta.tickets;
//         } else if (response.data.error) {
//           showToast("error", response.data.message);
//         }
//       } else {
//         const response = await fetchCompanyTickets(id);
//         if (!response.data.error) {
//           return response.data.meta.tickets;
//         } else if (response.data.error) {
//           showToast("error", response.data.message);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching tickets:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );

// const ticketsSlice = createSlice({
//   name: "tickets",
//   initialState: {
//     tickets: [],
//     ticketsLoaded: false,
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchTicketsForRole.pending, (state) => {
//         state.ticketsLoaded = false;
//         state.error = null;
//       })
//       .addCase(fetchTicketsForRole.fulfilled, (state, action) => {
//         state.tickets = action.payload;
//         state.ticketsLoaded = true;
//         state.error = null;
//       })
//       .addCase(fetchTicketsForRole.rejected, (state, action) => {
//         console.error("Error fetching tickets:", action.payload);
//         state.ticketsLoaded = true;
//         state.error = action.payload || "Error fetching tickets";
//       });
//   },
// });

// export default ticketsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchTicketsForAgent,
  fetchTicketsForEmployee,
  fetchCompanyTickets,
  fetchChildCompanyTicketsById,
} from "../config/webclient";
import {
  MANAGER_TICKETS,
  MANAGER_COUNT,
  CHILD_COMPANY_COUNT,
  AGENT_COUNT,
  EMPLOYEE_COUNT,
  COMPANY_COUNT,
  USER_COUNT,
} from "../config/api";
import { showToast } from "../components/global/showToast";

export const fetchCountsForRole = createAsyncThunk(
  "tickets/fetchCountsForRole",
  async ({ id, role }, { rejectWithValue }) => {
    try {
      let apiUrl;
      if (role === "employee") {
        // Attempt to fetch data from the manager API first
        const managerResponse = await fetch(MANAGER_COUNT(id));
        if (managerResponse.ok) {
          const managerData = await managerResponse.json();
          return managerData.meta;
        } else if (managerResponse.status !== 404) {
          // If the error is not 404, throw it
          throw new Error(
            `Error fetching counts for manager: ${managerResponse.statusText}`
          );
        }
        // If 404, fall back to the employee API
        apiUrl = EMPLOYEE_COUNT(id);
      } else {
        switch (role) {
          case "manager":
            apiUrl = MANAGER_COUNT(id);
            break;
          case "child":
            apiUrl = CHILD_COMPANY_COUNT(id);
            break;
          case "user":
            apiUrl = USER_COUNT(id);
            break;
          case "parent":
            apiUrl = COMPANY_COUNT(id);
            break;
          default:
            throw new Error("Invalid role specified.");
        }
      }

      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        return data.meta;
      } else {
        throw new Error(
          `Error fetching counts for ${role}: ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching counts:", error);
      return rejectWithValue(error.message);
    }
  }
);


// export const fetchCountsForRole = createAsyncThunk(
//   "tickets/fetchCountsForRole",
//   async ({ id, role }, { rejectWithValue }) => {
//     try {
//       let apiUrl;
//       switch (role) {
//         case "manager":
//           apiUrl = MANAGER_COUNT(id);
//           break;
//         case "child":
//           apiUrl = CHILD_COMPANY_COUNT(id);
//           break;
//         case "employee":
//           apiUrl = EMPLOYEE_COUNT(id);
//           break;
//           case "user":
//             apiUrl = USER_COUNT(id);
//             break;
//         case "parent":
//           apiUrl = COMPANY_COUNT(id);
//           break;
//         default:
//           throw new Error("Invalid role specified.");
//       }

//       const response = await fetch(apiUrl);
//       if (response.ok) {
//         const data = await response.json();
//         console.log(data.meta)
//         return data.meta;
//       } else {
//         throw new Error(
//           `Error fetching counts for ${role}: ${response.statusText}`
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching counts:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const fetchTicketsForRole = createAsyncThunk(
  "tickets/fetchTicketsForRole",
  async ({ id, role }, { rejectWithValue }) => {
    try {
      if (role === "user") {
        const response = await fetchTicketsForEmployee(id);
        if (!response.data.error) {
          return response.data.meta.tickets;
        } else {
          showToast("error", response.data.message);
        }
      } else if (["agent", "manager", "employee"].includes(role)) {
        let agentTickets = [];
        let managerTickets = [];
        try {
          const agentTicketsResponse = await fetchTicketsForAgent(id);
          agentTickets = agentTicketsResponse?.data?.meta?.tickets || [];
        } catch (error) {
          console.warn("Error fetching agent tickets:", error.message);
        }

        try {
          const managerTicketResponse = await fetch(`${MANAGER_TICKETS}/${id}`);
          if (managerTicketResponse.ok) {
            const managerTicketsData = await managerTicketResponse.json();
            managerTickets = managerTicketsData?.meta?.tickets || [];
          } else {
            throw new Error("Error fetching manager tickets");
          }
        } catch (error) {
          console.warn("Error fetching manager tickets:", error.message);
        }

        const combinedTickets = [...agentTickets, ...managerTickets];
        return combinedTickets.reduce((acc, current) => {
          if (!acc.some((item) => item.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);
      } else if (role === "child") {
        const response = await fetchChildCompanyTicketsById(id);
        if (!response.data.error) {
          return response.data.meta.tickets;
        } else {
          showToast("error", response.data.message);
        }
      } else {
        const response = await fetchCompanyTickets(id);
        if (!response.data.error) {
          return response.data.meta.tickets;
        } else {
          showToast("error", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      return rejectWithValue(error.message);
    }
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    ticketsLoaded: false,
    counts: {},
    countsLoaded: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketsForRole.pending, (state) => {
        state.ticketsLoaded = false;
        state.error = null;
      })
      .addCase(fetchTicketsForRole.fulfilled, (state, action) => {
        state.tickets = action.payload;
        state.ticketsLoaded = true;
        state.error = null;
      })
      .addCase(fetchTicketsForRole.rejected, (state, action) => {
        state.ticketsLoaded = true;
        state.error = action.payload || "Error fetching tickets";
      })
      .addCase(fetchCountsForRole.pending, (state) => {
        state.countsLoaded = false;
        state.error = null;
      })
      .addCase(fetchCountsForRole.fulfilled, (state, action) => {
        state.counts = action.payload;
        state.countsLoaded = true;
        state.error = null;
      })
      .addCase(fetchCountsForRole.rejected, (state, action) => {
        state.countsLoaded = true;
        state.error = action.payload || "Error fetching counts";
      });
  },
});

export default ticketsSlice.reducer;
