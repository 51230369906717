import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { Modal, Box, Typography, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { companyGet, employeeGet } from "../config/webclient";
import Loader from "../components/Loader";

export default function Profile() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [profileData, setProfileData] = useState("");
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const fetchProfile = async () => {
    try {
      let response;
      if (role === "parent" || role === "child") {
        response = await companyGet(id);
      } else {
        response = await employeeGet(id);
      }
      setProfileData(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [role]);

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid card p-3 my-3">
          <h6 className="text-grey mb-3">My Profile Settings</h6>
          <div className="row">
            <div className="col-md-3">
              <div className="bg-grey h-100 p-3">
                <p className="fw-500 f-14">Profile Details</p>
                <div className="my-2">
                  <p className="f-12 mb-2">
                    Full name:{" "}
                    <span className="fw-500">
                      {role === "parent" || role === "child"
                        ? profileData.ownerName
                        : profileData.name}
                    </span>
                  </p>
                  <p className="f-12 mb-2">
                    Email: <span className="fw-500">{profileData.email}</span>
                  </p>
                  <p className="f-12 mb-2">
                    Mobile #:{" "}
                    <span className="fw-500">{profileData.phoneNumber}</span>
                  </p>
                  <p className="f-12 mb-2">
                    Job title:{" "}
                    <span className="fw-500 text-capitalize">
                      {role === "parent" || role === "child"
                        ? "Owner"
                        : profileData.designation}
                    </span>
                  </p>
                </div>
                <hr />
                <div className="width-fit-content">
                  <p className="f-12 text-click" onClick={handleOpen}>
                    Change your password
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <form>
                <div className="my-2">
                  <img
                    className="user-img"
                    src="/images/assets/user.png"
                    alt="img"
                  />
                </div>
                <div>
                  <p className="f-14 mb-2 text-click">Add Photo</p>
                  <p className="f-12 mb-2">
                    A Profile image of the person, it's best if the picture has
                    the same length and height.
                  </p>
                </div>
                <div className="my-3">
                  <label className="mb-2 f-14 fw-500">Time Zone</label>
                  <select className="form-select form-select-sm no-box">
                    <option>UTC+5:30</option>
                    <option>UTC+5:30</option>
                    <option>UTC+5:30</option>
                    <option>UTC+5:30</option>
                    <option>UTC+5:30</option>
                  </select>
                </div>
                <div className="my-3 width-fit-content ml-auto">
                  <button className="btn btn-primary">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="h2">
              Change Password
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            margin="normal"
            label="Current Password"
            type="password"
            fullWidth
          />
          <TextField
            margin="normal"
            label="New Password"
            type="password"
            fullWidth
          />
          <TextField
            margin="normal"
            label="Confirm Password"
            type="password"
            fullWidth
          />
          <div className="my-2">
            <button className="btn btn-primary">Update Password</button>
          </div>
        </Box>
      </Modal>
    </MainLayout>
  );
}
