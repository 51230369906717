import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  PROJECT_GET,
  PROJECT_GET_BY_COMPANY_ID,
  PROJECT_MODULE_CREATE,
} from "../../config/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  Button,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MainLayout from "../../components/MainLayout";
import {
  addEmployeesToProject,
  assignProjectManager,
  fetchCompanyEmployees,
} from "../../config/webclient";
import Loader from "../../components/Loader";
import { showToast } from "../../components/global/showToast";

export default function Projects() {
  const [projects, setProjects] = useState([]);

  /* List of employees */
  const [employees, setEmployees] = useState([]);
  const [employeesLoaded, setEmployeesLoaded] = useState(false);
  const [employeeToBeAdded, setEmployeeToBeAdded] = useState(0);
  const [activeProject, setActiveProject] = useState(0);

  const [manager, setManager] = useState(0);
  const [showSelectMangaer, setShowSelectManager] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);

  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [moduleName, setModuleName] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");

  const [companyEmployees, setCompanyEmployees] = useState([]);
  const [comapnyEmployeesLoaded, setCompanyEmployeesLoaded] = useState(false);

  var id = 0;
  const role = localStorage.getItem("role");
  if (role === "child" || role === "parent") {
    id = localStorage.getItem("id");
  }

  const fetchEmployeesForCompany = async () => {
    try {
      const companyId = localStorage.getItem("id");
      await fetchCompanyEmployees(companyId)
        .then((response) => {
          setCompanyEmployees(response.data.meta.employees);
          setCompanyEmployeesLoaded(true);
        })
        .catch((error) => {
          setCompanyEmployeesLoaded(false);
          console.log(error);
        });
    } catch (error) {
      setCompanyEmployeesLoaded(false);
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    const companyId = localStorage.getItem("id");

    if (!companyId) {
      setError("Company ID is missing in local storage");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(PROJECT_GET_BY_COMPANY_ID(companyId));
      if (!response.data.error) {
        setProjects(response.data.meta.projects);
      } else if (response.data.error) {
        showToast("error", response.data.message);
      }
    } catch (error) {
      showToast("error", "No Project Found");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  /* Fetch company employees in the background. */
  const fetchEmployees = async () => {
    const companyId = localStorage.getItem("id");

    if (!companyId) {
      setError("Company ID is missing in local storage");
      setLoading(false);
      return;
    }

    try {
      await fetchCompanyEmployees(companyId).then((response) => {
        setEmployees(response.data.meta.employees);
        setEmployeesLoaded(true);
      });
    } catch (error) {
      setEmployeesLoaded(false);
      setError("Error fetching company employees");
      console.log(error);
    }
  };

  const addEmployeeToProject = async () => {
    if (employeeToBeAdded > 0 && activeProject > 0) {
      try {
        await addEmployeesToProject({
          projectId: activeProject,
          employeeIds: [employeeToBeAdded],
        }).then((response) => {
          setEmployees(response.data);
          console.log(response.data);
          if (!response.data.error) {
            showToast("success", response.data.message);
            setEmployeesLoaded(true);
            fetchProjects();
          } else if (response.data.error) {
            showToast("error", response.data.message);
          }
        });
      } catch (error) {
        setError("Error adding employees");
        console.log(error);
        showToast("error", error.data.message);
      }
    }
  };

  const handleAddEmployees = async () => {
    await addEmployeeToProject().then((response) => {
      setAddEmployeeOpen(false);
    });
  };

  const handleAddManager = async () => {
    console.log(activeProject + " " + manager);
    if (activeProject > 0 && manager > 0) {
      await assignProjectManager({
        projectId: activeProject,
        employeeId: manager,
      }).then((response) => {
        console.log(response);
        if (!response.data.error) {
          showToast("success", response.data.message);
          setShowSelectManager(false);
          fetchProjects();
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }
      });
    }
  };

  useEffect(() => {
    fetchEmployees();
    // fetchCompanyEmployees()
    fetchProjects();
    fetchEmployeesForCompany();
  }, []);

  const handleClickOpen = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModuleName("");
    setModuleDescription("");
  };

  const handleAddEmployeeClose = () => {
    setAddEmployeeOpen(false);
  };

  const handleAddManagerClose = () => {
    setShowSelectManager(false);
  };

  const handleAddModule = async () => {
    if (!selectedProject) return;

    const payload = {
      name: moduleName,
      description: moduleDescription,
      projectId: selectedProject.id,
    };

    try {
      const response = await axios.post(PROJECT_MODULE_CREATE, payload);

      if (!response.data.error) {
        showToast("success", response.data.message);
        fetchProjects();
      }

      handleClose();
    } catch (error) {
      console.error("Error adding module", error);
    }
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid" style={{ padding: 20 }}>
          <Typography variant="h5" gutterBottom>
            Projects
          </Typography>
          {projects.length === 0 ? (
            <Typography>No projects found</Typography>
          ) : projects.length > 0 ? (
            projects.map((project) => {
              return (
                <Accordion key={project.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${project.id}a-content`}
                    id={`panel${project.id}a-header`}
                  >
                    <IconButton edge="start">
                      <AssignmentIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      {project.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <strong>Created:</strong>{" "}
                    {new Date(project.createdAt).toLocaleDateString("en-GB")}
                    <Typography className="mb-3" variant="body1">
                      <strong>Description:</strong> {project.description}
                    </Typography>
                    <div className="row project-cards">
                      <div className="col-md-4">
                        <div className="card p-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <strong>Manager:</strong>

                            {project.roles.length > 0 ? (
                              project.roles.map((employee) =>
                                employee.role === "manager" ? (
                                  <p className="mb-0" key={employee.employeeId}>
                                    <strong> {employee.employeeName}</strong>
                                  </p>
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <button
                                className="btn btn-primary bg-primary width-fit-content"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setShowSelectManager(true);
                                  setActiveProject(project.id);
                                }}
                              >
                                +
                              </button>
                            )}
                          </div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          ></Typography>
                          <Typography variant="body2" color="textSecondary">
                            <strong>Employees:</strong>{" "}
                            {project.employees.length || "No employees"}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <strong>Tickets:</strong>{" "}
                            {project.tickets.length || "No tickets"}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <strong>Modules:</strong>{" "}
                            {project.projectModules.length || "No modules"}
                          </Typography>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card p-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0">Assigned Employees</h6>
                            <button
                              className="btn btn-primary bg-primary width-fit-content"
                              onClick={(event) => {
                                event.preventDefault();
                                setAddEmployeeOpen(true);
                                setActiveProject(project.id);
                              }}
                            >
                              +
                            </button>
                          </div>
                          <ul>
                            {project.roles.length > 0 ? (
                              project.roles.map((employee) =>
                                employee.role === "employee" ? (
                                  <li key={employee.employeeId}>
                                    {employee.employeeName}
                                  </li>
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <li>No employees assigned</li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card p-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0">Modules</h6>
                            <button
                              className="btn btn-primary bg-primary width-fit-content"
                              onClick={() => handleClickOpen(project)}
                            >
                              +
                            </button>
                          </div>
                          <ul>
                            {project.projectModules.length > 0 ? (
                              project.projectModules.map((module) => (
                                <li key={module.id}>{module.name}</li>
                              ))
                            ) : (
                              <li>No modules created</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            ""
          )}

          {/* Modal for Adding Modules */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Module</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Module Name"
                type="text"
                fullWidth
                variant="outlined"
                value={moduleName}
                onChange={(e) => setModuleName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                value={moduleDescription}
                onChange={(e) => setModuleDescription(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddModule} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal for adding employees to a project. */}
          <Dialog open={addEmployeeOpen} onClose={handleAddEmployeeClose}>
            <DialogTitle>Assign Agent</DialogTitle>
            <DialogContent sx={{ width: 400 }}>
              {employeesLoaded && (
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={employees}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setEmployeeToBeAdded(value.id)}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Agents" />
                  )}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddEmployeeClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddEmployees} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal for adding manager to a project. */}
          <Dialog open={showSelectMangaer} onClose={handleAddManagerClose}>
            <DialogTitle>Add Manager</DialogTitle>
            <DialogContent sx={{ width: 400 }}>
              {employeesLoaded && (
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={employees}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setManager(value.id)}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Employee" />
                  )}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddManagerClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddManager} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </MainLayout>
  );
}
