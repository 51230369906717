import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MainLayout from "../../components/MainLayout";
import { COMPANY_CREATE, COMPANY_CREATE_CHILD } from "../../config/api";
import axios from "axios";
import {
  enforce10DigitNumbersNoSpaces,
  enforceNoSpaces,
  enforceTextAndSpacesOnly,
} from "../../config/utility";
import { Link } from "react-router-dom";
import { showToast } from "../../components/global/showToast";

export default function CreateCompany() {
  const role = localStorage.getItem("role");
  const companyId = localStorage.getItem("id");
  const [formData, setFormData] = useState({
    name: "",
    ownerName: "",
    email: "",
    phoneNumber: "",
    address: "",
    timezone: "",
    password: "",
    parentCompanyId: companyId,
  });
  const [attachment, setAttachment] = useState(null);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleAttachmentChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response;

      if (role === "parent") {
        // For parent creating a child company
        response = await axios.post(COMPANY_CREATE_CHILD, formData);
        console.log(response);
        if (!response.data.error) {
          showToast("success", "Company Created Successfully");
          setFormData({
            name: "",
            ownerName: "",
            email: "",
            phoneNumber: "",
            address: "",
            timezone: "",
            password: "",
            parentCompanyId: companyId, // Retain the parentCompanyId as it is
          });
  
          // Optionally, reset attachment if needed
          setAttachment(null)
          window.location.href =
            role === "parent" ? "/company/company" : "/superadmin/company";
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("ownerName", formData.ownerName);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("phoneNumber", formData.phoneNumber);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("timezone", formData.timezone || "UTC");
        formDataToSend.append("password", formData.password);

        if (attachment) {
          formDataToSend.append("logo", attachment);
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        response = await axios.post(COMPANY_CREATE, formDataToSend, config);
        if (!response.data.error) {
          window.location.href =
            role === "parent" ? "/company/company" : "/superadmin/company";
        }
      }
    
    } catch (error) {
      showToast("error", error.response.data.message);
    }
  };

  return (
    <MainLayout>
      <div className="container my-2">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-5">
              <h5>
                {role === "parent" ? "Create Client Company" : "Create Company"}
              </h5>
              <p className="mb-0 f-12">Enter the details below</p>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <TextField
                    id="name"
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="ownerName"
                    label="Owner Name"
                    variant="outlined"
                    fullWidth
                    value={formData.ownerName}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="phoneNumber"
                    label="Mobile"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforce10DigitNumbersNoSpaces}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    type="email"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceNoSpaces}
                  />
                </div>
                {role !== "parent" && (
                  <div className="col-md-6">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleAttachmentChange}
                      style={{ marginTop: "1rem" }}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <button type="submit" className="btn btn-success w-content">
                    Create
                  </button>

                  <Link
                    to={
                      role === "superadmin"
                        ? "/superadmin/company"
                        : "/company/company"
                    }
                  >
                    <button className="btn btn-secondary mx-2">Cancel</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
