export const enforceMaxLength = (event) => {
  if (event.target.value.length > 10) {
    event.target.value = event.target.value.slice(0, 10);
  }
};

export const enforceNoSpecialChars = (event) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const enforceNoSpacesOrSpecialChars = (event) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
};

export const enforceNoSpaces = (event) => {
  event.target.value = event.target.value.replace(/\s/g, "");
};

export const enforceTextAndSpacesOnly = (event) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z ]/g, "");
};

export const enforce10DigitNumbersNoSpaces = (event) => {
  let value = event.target.value.replace(/\D/g, "");
  value = value.replace(/\s/g, "");
  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  event.target.value = value;
};


export const formattedTime = (milliseconds) => {
  const totalSeconds = Math.round(milliseconds / 1000);
  const h = Math.floor(totalSeconds / 3600);
  const m = Math.floor((totalSeconds % 3600) / 60);
  const s = totalSeconds % 60;

  let result = "";

  if (h > 0) {
    result += `${h} hour${h > 1 ? "s" : ""} `;
  }
  if (m > 0) {
    result += `${m} min `;
  }
  if (s > 0) {
    result += `${s} sec`;
  }

  return result.trim();
};