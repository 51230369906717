import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MainLayout from "../../components/MainLayout";
import { EMPLOYEE_CREATE } from "../../config/api";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  enforce10DigitNumbersNoSpaces,
  enforceNoSpaces,
  enforceNoSpecialChars,
  enforceTextAndSpacesOnly,
} from "../../config/utility";
import { Link } from "react-router-dom";
import { showToast } from "../../components/global/showToast";

export default function CreateAgent() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    designation: "",
    address: "",
    password: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const role = localStorage.getItem("role");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const companyId = localStorage.getItem("id");

    if (!companyId) {
      handleSnackbar("Company ID is missing in local storage", "error");
      return;
    }

    try {
      // Set role as 'employee'
      const response = await axios.post(EMPLOYEE_CREATE, {
        ...formData,
        role: "employee",
        companyId,
        timezone: "",
      });
      console.log("Agent created successfully:", response.data);
      if (!response.data.error) {
        showToast("success", response.data.message);
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          designation: "",
          address: "",
          password: "",
        });
      } else if (response.data.error) {
        showToast("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message)
      showToast("error", error.response.data.message);
    }
  };

  return (
    <MainLayout>
      <div className="container my-2">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-5">
              <h5>{role === "parent" ? "Create Agent" : "Create User"}</h5>
              <p className="mb-0 f-12">Enter the details below</p>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    type="email"
                    onInput={enforceNoSpaces}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="phoneNumber"
                    label="Phone Number"
                    type="tel"
                    variant="outlined"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforce10DigitNumbersNoSpaces}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="designation"
                    label="Designation"
                    variant="outlined"
                    fullWidth
                    value={formData.designation}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceNoSpecialChars}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceNoSpaces}
                  />
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-success w-content">
                    Create
                  </button>
                  <Link
                    to={role === "parent" ? "/company/agents" : "/child/agents"}
                  >
                    <button className="btn btn-secondary mx-2">Cancel</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </MainLayout>
  );
}
