import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { companyGet, employeeGet } from "../config/webclient";
import { useNavigate } from "react-router-dom";

export default function SideProfile() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const navigate = useNavigate();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  //   fetch
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [profileData, setProfileData] = useState("");
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const fetchProfile = async () => {
    try {
      let response;
      if (role === "parent" || role === "child") {
        response = await companyGet(id);
      } else {
        response = await employeeGet(id);
      }
      setProfileData(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role !== "superadmin") {
    fetchProfile();
    }
  }, []);

  useEffect(() => {
    const savedTimeZone = localStorage.getItem("timezone");
    if (savedTimeZone) {
      setSelectedTimeZone(savedTimeZone);
    }
  }, []);

  const handleTimeZoneChange = (event) => {
    const timeZone = event.target.value;
    setSelectedTimeZone(timeZone);
    localStorage.setItem("timezone", timeZone);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <List sx={{ top: "70px" }}>
        <ListItem disablePadding>
          <ListItemText>
            <div className="row mx-0">
              <div className="col-md-12">
                <div className="bg-grey h-100 p-3">
                  <p className="fw-500 f-14">Profile Details</p>
                  <div className="my-2">
                    <img
                      className="user-img"
                      src="/images/assets/user.png"
                      alt="img"
                    />
                    <div className="my-3">
                      <p className="f-12 mb-2">
                        <b>Name:</b>{" "}
                        <span className="fw-500">
                          {role === "parent" || role === "child"
                            ? profileData.ownerName
                            : profileData.name}
                        </span>
                      </p>
                      <p className="f-12 mb-2">
                        <b>Email:</b>{" "}
                        <span className="fw-500">{profileData.email}</span>
                      </p>
                      <p className="f-12 mb-2">
                        <b>Mobile:</b>{" "}
                        <span className="fw-500">
                          {profileData.phoneNumber}
                        </span>
                      </p>
                      <p className="f-12 mb-2">
                        <b>Designation:</b>{" "}
                        <span className="fw-500 text-capitalize">
                          {role === "parent" || role === "child"
                            ? "Owner"
                            : profileData.designation}
                        </span>
                      </p>

                      <div className="f-12">
                        <p className="mb-0">
                          <b>Time Zone:</b>
                        </p>
                        <select
                          className="form-select no-box-shadow f-12"
                          value={selectedTimeZone}
                          onChange={handleTimeZoneChange}
                        >
                          <option value="UTC">
                            UTC (Coordinated Universal Time)
                          </option>
                          <option value="GMT">GMT (Greenwich Mean Time)</option>
                          <option value="EST">
                            EST (Eastern Standard Time) - UTC -5:00
                          </option>
                          <option value="CST">
                            CST (Central Standard Time) - UTC -6:00
                          </option>
                          <option value="PST">
                            PST (Pacific Standard Time) - UTC -8:00
                          </option>
                          <option value="IST">
                            IST (Indian Standard Time) - UTC +5:30
                          </option>
                          <option value="CET">
                            CET (Central European Time) - UTC +1:00
                          </option>
                          <option value="AEST">
                            AEST (Australian Eastern Standard Time) - UTC +10:00
                          </option>
                          <option value="JST">
                            JST (Japan Standard Time) - UTC +9:00
                          </option>
                          <option value="EET">
                            EET (Eastern European Time) - UTC +2:00
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="f-12 w-fit-content ml-auto">
                    <button
                      className="btn btn-danger f-12"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button onClick={toggleDrawer("right", true)}>
          <AccountCircleIcon sx={{ color: "#747474" }} variant="contained" />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
