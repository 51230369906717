import { React, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import TimeAgo from "./TimeAgo";
import DateTime from "./DateTime";
import AttachmentIcon from "@mui/icons-material/Attachment";

const TicketMessageLog = ({ props }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  return (
    // <div className="card box-main-card my-1 p-1">
    <div className="card no-border my-1 p-1">
      <div className="row m-2">
        <div className="chat-card-ticket col-md-1">
          <Tooltip
            title={props.senderName}
            arrow
            placement="bottom"
            key={props.id}
          >
            <p className="mb-0 text-center">{props.senderName[0]}</p>
          </Tooltip>
        </div>
        <div className="col-md-10">
          <p className="mb-0">
            {props.senderName}{" "}
            <span className="ticket-time f-12">
              <TimeAgo createdAt={props.timeStamp} />
            </span>
          </p>
          {/* <p className="mb-0 f-12">
            <TimeAgo createdAt={props.timeStamp} />{" "}
            <DateTime createdAt={props.timeStamp} />
          </p> */}
          <div className="border-ticket-msg my-2 px-3 py-1">
            <div dangerouslySetInnerHTML={{ __html: props.message }} />
          </div>

          <div>
            {props.attachment ? (
              <a
                className="text-decoration-none"
                href={props.attachment}
                target="_blank"
              >
                <button className="btn btn-primary f-12 bg-primary f-14 d-flex align-items-center justify-content-center">
                  <AttachmentIcon sx={{ fontSize: 14, marginRight: 0.5 }} />
                  File
                </button>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketMessageLog;
