import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { COMPANY_ALL } from "../../config/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { fetchCompanyChildren } from "../../config/webclient";
import { Link } from "react-router-dom";
import { showToast } from "../../components/global/showToast";

export default function ViewAllCompany() {
  const [companies, setCompanies] = useState([]);
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");

  const handleError = (error) => {
    const message = error.response?.data?.message || "An error occurred.";
    console.error("Error:", message);
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(COMPANY_ALL);
      if (!response.data.error) {
        setCompanies(response.data.meta.companies);
      } else {
        showToast("error", response.data.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchChildren = async () => {
    try {
      const response = await fetchCompanyChildren(id);
      if (!response.data.error) {
        setCompanies(response.data.meta.childCompanies);
      } else {
        showToast("error", response.data.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    role === "superadmin" ? fetchCompanies() : fetchChildren();
  }, []);

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between my-2">
              <h5>
                {role === "superadmin" ? "All Companies" : "Your Customers"}
              </h5>
              <Link
                to={
                  role === "superadmin"
                    ? "/superadmin/create-company"
                    : "/company/create-company"
                }
              >
                <button className="btn btn-primary bg-primary">
                  Create Company
                </button>
              </Link>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Owner Name</TableCell>
                    {role === "superadmin" && <TableCell>Logo</TableCell>}
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies.map((company) => (
                    <TableRow key={company.id}>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.ownerName}</TableCell>
                      {role === "superadmin" && (
                        <TableCell>
                          {company.logo ? (
                            <img
                              className="logo-table"
                              src={company.logo}
                              alt="Logo"
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      )}
                      <TableCell>{company.email}</TableCell>
                      <TableCell>{company.phoneNumber}</TableCell>
                      <TableCell>{company.address}</TableCell>
                      <TableCell>
                        {new Date(company.createdAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
