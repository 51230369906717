import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../components/MainLayout";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import {
  companyGetAll,
  companyGetProjects,
  createTicket,
  fetchAllEmployees,
  fetchCompanyChildren,
  fetchCompanyEmployees,
  fetchCompanyTickets,
  fetchParentCompany,
  fetchProjectModules,
  fetchTicketsForAgent,
  fetchTicketsForEmployee,
  generateTicket,
} from "../config/webclient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TicketCard from "../components/TicketCard";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import UploadModal from "../components/UploadModal";
import Tooltip from "@mui/material/Tooltip";
import { Chip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { GENERATE_DESCRIPTION, MANAGER_TICKETS } from "../config/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchTicketsForRole } from "../tickets/ticketsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function NewTicket() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [companyId, setCompanyId] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [childCompanies, setChildCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  const [employeeId, setEmployeeId] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeesLoaded, setEmployeesLoaded] = useState(false);

  const [allEmployees, setAllEmployees] = useState([]);
  const [allEmployeesLoaded, setAllEmployeesLoaded] = useState(false);

  const [raisedById, setRaisedById] = useState(0);

  const [companyProjects, setCompanyProjects] = useState([]);
  const [companyProjectsLoaded, setCompanyProjectsLoaded] = useState(false);
  const [companyProjectId, setCompanyProjectId] = useState(0);

  const [companyProjectModules, setCompanyProjectModules] = useState([]);
  const [projectModulesLoaded, setProjectModulesLoaded] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [contact, setContact] = useState("");
  const [buisnessImpact, setBuisnessImpact] = useState("");
  const [cc, setCc] = useState("");

  const [estimatedHours, setEstimatedHours] = useState("");
  const [actualSupportHours, setActualSupportHours] = useState("");
  const [actualCompletionDate, setActualCompletionDate] = useState("");
  const [estimatedCompletionDate, setEstimatedCompletionDate] = useState("");

  const [approvedTime, setApprovedTime] = useState("");

  const [ticketType, setTicketType] = useState("");
  const [ticketstatus, setTicketStatus] = useState("");

  const [ticketPriority, setTicketPriority] = useState("");
  const [projectId, setProjectId] = useState(0);
  const [projectModuleId, setProjectModuleId] = useState(0);
  const [childCompanyId, setChildCompanyId] = useState(0);

  var role = localStorage.getItem("role");
  var id = localStorage.getItem("id");

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        var role = localStorage.getItem("role");
        var companyId;
        if (
          role === "user" ||
          role === "employee" ||
          role === "agent" ||
          role === "manager"
        ) {
          companyId = localStorage.getItem("companyId");
        } else {
          companyId = localStorage.getItem("id");
        }
        await fetchParentCompany(companyId).then((response) => {
          setCompanies(response.data);
          setCompaniesLoaded(true);
        });
      } catch (error) {
        setCompaniesLoaded(false);
        console.log(error);
      }
    };
    fetchCompanies();
  }, []);

  const fetchChildren = async () => {
    let id;

    if (role === "parent" || role === "employee") {
      id =
        role === "parent"
          ? localStorage.getItem("id")
          : localStorage.getItem("companyId");

      try {
        const response = await fetchCompanyChildren(id);
        console.log("child iddd");
        console.log(response.data);
        setChildCompanies(response.data.meta.childCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    } else if (role === "child" || role === "user") {
      id =
        role === "child"
          ? localStorage.getItem("id")
          : localStorage.getItem("companyId");
      setChildCompanyId(id);
    }
  };

  useEffect(() => {
    fetchChildren();
  }, []);

  useEffect(() => {
    const fetchEmployeesAll = async (id) => {
      try {
        setEmployeesLoaded(false);
        await fetchAllEmployees(id).then((response) => {
          setAllEmployees(response.data);

          setAllEmployeesLoaded(true);
        });
      } catch (error) {
        setAllEmployeesLoaded(false);
        console.log(error);
      }
    };
    fetchEmployeesAll();
  }, []);

  const fetchEmployees = async (id) => {
    try {
      setEmployeesLoaded(false);
      await fetchCompanyEmployees(id).then((response) => {
        setEmployees(response.data.meta.employees);
        setEmployeesLoaded(true);
      });
    } catch (error) {
      setEmployeesLoaded(false);
      console.log(error);
    }
  };

  const fetchCompanyProjects = async (id) => {
    try {
      setEmployeesLoaded(false);
      await companyGetProjects(id).then((response) => {
        console.log("khalid", response.meta);

        if (!response.data.error) {
          setCompanyProjects(response.data.meta.projects);
          setCompanyProjectsLoaded(true);
        } else if (response.data.error) {
          console.log("error", response.data.message);
        }
      });
    } catch (error) {
      console.log("error", error.response.data.message);
      setCompanyProjectsLoaded(false);
      console.log(error);
    }
  };

  const fetchCompanyProjectModules = async (prjectId) => {
    try {
      setEmployeesLoaded(false);
      await fetchProjectModules(projectId).then((response) => {
        console.log("khalid", response.data.meta);
        setCompanyProjectModules(response.data.meta);

        setProjectModulesLoaded(true);
      });
    } catch (error) {
      setProjectModulesLoaded(false);
      console.log(error);
    }
  };

  const onCompanyChange = async (event, value) => {
    if (value.id != null) {
      console.log(value.id);
      setCompanyId(value.id);
      await [fetchEmployees(value.id), fetchCompanyProjects(value.id)];
    }
  };

  const onChildCompanyChange = async (event, value) => {
    if (value.id != null) {
      console.log(value.id);
      setChildCompanyId(value.id);
    }
  };

  const onContactChange = async (event, value) => {
    setContact(value.phoneNumber);
  };

  const onEmployeeChange = async (event, value) => {
    setEmployeeId(value.id);
  };

  const onRaisedByChange = async (event, value) => {
    setRaisedById(value.id);
  };

  const onCompanyProjectChange = async (event, value) => {
    if (value != null) {
      setProjectId(value.id);
      console.log(value.projectModules);
      setCompanyProjectModules(value.projectModules);
      setProjectModulesLoaded(true);
    }
  };

  const onProjectModuleChange = async (event, value) => {
    setProjectModuleId(value.id);
  };

  const onApprovedTimeChanged = async (event) => {
    setApprovedTime(event.target.value);
  };

  const type = [
    { label: "Incident" },
    { label: "Query" },
    { label: "Change Request" },
    { label: "Training" },
    { label: "Project" },
    { label: "Service Request" },
  ];
  const status = [
    { label: "Open" },
    { label: "In-Process" },
    { label: "Pending" },
    { label: "Awaiting on Customer" },
    { label: "Resolved" },
    { label: "Reopen" },
    { label: "Closed" },
  ];

  const userStatus = ["Resolved", "Pending", "In-Process"];

  const filteredStatus =
    role === "user" || role === "child"
      ? status.filter((item) => userStatus.includes(item.label))
      : status;

  const priority = [
    { label: "Low" },
    { label: "Medium" },
    { label: "High" },
    { label: "Urgent" },
  ];

  const toolbar = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const onCreateTIcketSubmit = async (event) => {
    const role = localStorage.getItem("role");
    var raisedByAgentId;
    if (role === "parent" || role === "child") {
      raisedByAgentId = localStorage.getItem("ownerId");
    } else {
      raisedByAgentId = localStorage.getItem("id");
    }
    event.preventDefault();
    const emailString = emailList.join(" ");
    await createTicket({
      childCompanyId: childCompanyId,
      projectId: projectId,
      employeeId: raisedByAgentId,
      agentId: role === "user" && role !== "child" ? null : employeeId,
      title: title,
      description: description,
      contact: contact,
      type: ticketType,
      contact: role !== "user" && role !== "child" ? "N/A" : contact,
      // contact: contact,
      // estimatedHours: role === "user" ? 1 : estimatedHours,
      estimatedHours:
        role !== "user" && role !== "child" ? "N/A" : estimatedHours,
      actualSupportHours:
        role === "user" && role === "child" ? "N/A" : actualSupportHours,
      buisnessImpact: buisnessImpact,
      approvedTime: role !== "user" && role !== "child" ? "N/A" : approvedTime,
      priority: ticketPriority,
      projectModuleId: projectModuleId,
      cc: emailString,
      status: ticketstatus.toLowerCase(),
      estimatedCompletionDate:
        role !== "user" && role !== "child" ? "N/A" : estimatedCompletionDate,
      actualCompletionDate:
        role === "user" && role === "child" ? "N/A" : actualCompletionDate,
    })
      .then((response) => {
        console.log(response);
        handleSnackbar("Ticket Raised", "success");
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const navigateToPage = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }
    navigate(`/${role}/ticket/${id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const [aiRespond, setAirespond] = useState(false);

  const handleGenerateTicket = async () => {
    setAirespond(true);
    handleSnackbar("Please wait, AI is generating the description...", "info");

    try {
      const cleanedDescription = stripHtmlTags(description);

      const formData = new FormData();
      formData.append("subject", title);
      formData.append("short_description", cleanedDescription);

      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      const response = await axios.post(GENERATE_DESCRIPTION, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data.error.error_status);
      if (!response.data.error.error_status) {
        setDescription(response.data.Description);
        handleSnackbar("Description generated successfully", "success");
        setAirespond(false);
      } else {
        handleSnackbar(response.data.error.error_message, "error");
        setAirespond(false);
      }
    } catch (error) {
      console.error(
        "Failed to generate ticket:",
        error.response?.data || error.message
      );
      handleSnackbar("Failed to generate description", "error");
      setAirespond(false);
    }
  };

  const quillRef = useRef(null);

  const clearEditor = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.setText("");
      setDescription("");
    }
  };
  console.log(description);

  const [emailList, setEmailList] = useState([]);

  const handleCcChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.includes(" ")) {
      const emails = inputValue.trim().split(" ");

      const validEmails = emails.filter((email) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      );

      if (validEmails.length) {
        setEmailList((prevEmails) => [...prevEmails, ...validEmails]);
      }
      setCc("");
    } else {
      setCc(inputValue);
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmailList((prevEmails) =>
      prevEmails.filter((email) => email !== emailToDelete)
    );
  };

  const dispatch = useDispatch();
  const { tickets, ticketsLoaded, error } = useSelector(
    (state) => state.tickets
  );
  useEffect(() => {
    if (role && id) {
      dispatch(fetchTicketsForRole({ id: id, role }));
    }
  }, [dispatch, role, id]);

  return (
    <div className="height-100-hidden">
      <MainLayout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 height-75">
              <div className="card p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Create New Ticket</h5>
                  {role === "parent" ? (
                    <>
                      <button
                        className="btn btn-primary bg-primary"
                        onClick={() => setModalOpen(true)}
                      >
                        Bulk Upload
                      </button>
                      <UploadModal
                        open={modalOpen}
                        handleClose={() => setModalOpen(false)}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                  >
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>
                <form>
                  {companiesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={[companies]}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => onCompanyChange(event, value)}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Group" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  {role === "parent" || role === "employee" ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={childCompanies}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) =>
                        onChildCompanyChange(event, value)
                      }
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Company" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  {employeesLoaded && role !== "user" && role !== "child" ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={employees}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) =>
                        onEmployeeChange(event, value)
                      }
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Agent" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <TextField
                    id="outlined-basic"
                    label="Subject"
                    onChange={(event, value) => setTitle(event.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    disabled={aiRespond}
                  />
                  <div className="my-2">
                    <label>Description</label>
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      modules={toolbar}
                      formats={formats}
                      readOnly={aiRespond}
                      ref={quillRef}
                    />
                  </div>
                  <button
                    className="btn btn-primary bg-primary ai-gen-btn f-12"
                    type="button"
                    onClick={() => handleGenerateTicket()}
                    disabled={aiRespond || !(title && description)}
                  >
                    <i
                      className={
                        aiRespond ? "fa fa-spinner fa-spin" : "fa fa-magic"
                      }
                    />
                    {aiRespond
                      ? " AI is generating a response..."
                      : " Let AI write for you"}
                  </button>
                  <Tooltip
                    title="Please provide a subject and a brief description"
                    arrow
                    placement="right"
                  >
                    <button className="btn f-14" type="button">
                      <i class="fa fa-info-circle text-theme"></i>
                    </button>
                  </Tooltip>

                  {role !== "user" && role !== "child" && employeesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={employees}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => onContactChange(event, value)}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Contact" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <>
                    <div className="d-flex">
                      <TextField
                        id="outlined-basic"
                        label="CC"
                        value={cc}
                        onChange={handleCcChange}
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: 2, marginBottom: 2 }}
                      />
                      <Tooltip
                        title="Press space to add multiple email addresses"
                        arrow
                        placement="right"
                      >
                        <button className="btn f-14" type="button">
                          <i class="fa fa-info-circle text-theme"></i>
                        </button>
                      </Tooltip>
                    </div>

                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {emailList.map((email, index) => (
                        <Chip
                          key={index}
                          label={email}
                          onDelete={() => handleDeleteEmail(email)}
                          deleteIcon={
                            <IconButton aria-label="delete">
                              <CloseIcon />
                            </IconButton>
                          }
                          sx={{ borderRadius: "50px" }} // Pill shape
                        />
                      ))}
                    </Box>
                  </>
                  <label className="m-2">
                    Raised by <br /> <b> {localStorage.getItem("name")}</b>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={type}
                        defaultValue={type[0]}
                        onChange={(event, value) => setTicketType(value.label)}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" />
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={filteredStatus}
                        defaultValue={filteredStatus[0]}
                        onChange={(event, value) =>
                          setTicketStatus(value.label)
                        }
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )}
                      />
                    </div>
                  </div>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={priority}
                    defaultValue={priority[0]}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => setTicketPriority(value.label)}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Priority" />
                    )}
                  />

                  {companiesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={companyProjects}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) =>
                        onCompanyProjectChange(event, value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Project" />
                      )}
                    />
                  ) : (
                    ""
                  )}
                  {projectModulesLoaded ? (
                    <>
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={companyProjectModules}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                          onProjectModuleChange(event, value)
                        }
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Modules" />
                        )}
                      />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Text in a modal
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Duis mollis, est non commodo luctus, nisi erat
                            porttitor ligula.
                          </Typography>
                        </Box>
                      </Modal>
                    </>
                  ) : (
                    "Please select a project"
                  )}
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          label="Estimated Hours"
                          onChange={(event) =>
                            setEstimatedHours(event.target.value)
                          }
                          variant="outlined"
                          disabled={
                            role != "user" &&
                            role != "child" &&
                            role != "parent" &&
                            role != "employee"
                          }
                          fullWidth
                          defaultValue={
                            role == "user" ||
                            role == "child" ||
                            role != "parent" ||
                            role != "employee"
                              ? 1
                              : "N/A"
                          }
                          sx={{ marginTop: 2, marginBottom: 2 }}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          label="Approved  Hours"
                          onChange={(event) =>
                            setApprovedTime(event.target.value)
                          }
                          variant="outlined"
                          disabled={role != "user" && role != "child"}
                          fullWidth
                          defaultValue={
                            role == "user" || role == "child" ? 1 : "N/A"
                          }
                          sx={{ marginTop: 2, marginBottom: 2 }}
                        />
                      </div>
                      {/* {role != "user" ? ( */}
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          label="Actual Support Hours"
                          onChange={(event) =>
                            setActualSupportHours(event.target.value)
                          }
                          disabled={role == "user" || role == "child"}
                          variant="outlined"
                          fullWidth
                          defaultValue={
                            role == "user" || role == "child" ? "N/A" : 1
                          }
                          sx={{ marginTop: 2, marginBottom: 2 }}
                        />
                      </div>
                    </div>
                  </>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Estimated Completion Date</label>
                      <input
                        className="form-control my-2"
                        type="date"
                        disabled={
                          role != "user" &&
                          role != "child" &&
                          role != "parent" &&
                          role != "employee"
                        }
                        onChange={(event) =>
                          setEstimatedCompletionDate(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Actual Completion Date</label>
                      <input
                        className="form-control my-2"
                        type="date"
                        disabled={role == "user" || role == "child"}
                        onChange={(event) =>
                          setActualCompletionDate(event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <TextField
                    id="outlined-basic"
                    onChange={(event) => setBuisnessImpact(event.target.value)}
                    label="Business Impact"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary bg-primary mr-1 ai-gen-btn"
                      onClick={(event) => onCreateTIcketSubmit(event)}
                      disabled={
                        !title || !description || !projectId || !projectModuleId
                      }
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 height-75">
              {/* <div className="card p-3">
                <h5>Tickets</h5>

                {ticketsLoaded && tickets.length === 0 && (
                  <p className="mb-0 text-center f-12">No Ticket Found</p>
                )}

                <div className="my-2 row col-md-12">
                  {ticketsLoaded && tickets.length > 0 ? (
                    tickets
                      .filter((ticket) =>
                        title === ""
                          ? true
                          : ticket.title
                              .toLowerCase()
                              .includes(title.toLowerCase())
                      )
                      .map((ticket) => (
                        <div className="col-md-12 mb-2" key={ticket.id}>
                          <TicketCard
                            props={{
                              id: ticket.id,
                              title: ticket.title,
                              description: ticket.description,
                              agent: ticket.agent
                                ? ticket.agent.name
                                : "Unassigned",
                              status: ticket.status,
                              employee: ticket.employee.name,
                              createdAt: ticket.createdAt,
                              priority: ticket.priority,
                              project: ticket.projectModule,
                              type: ticket.type,
                              fetchPreview: navigateToPage,
                            }}
                          />
                        </div>
                      ))
                  ) : (
                    <div className="col-md-12">
                      <Loader />
                    </div>
                  )}
                </div>
              </div> */}

              <div className="card p-3">
                <h5>Tickets</h5>

                <div className="my-2 row col-md-12">
                  {ticketsLoaded ? (
                    tickets.length > 0 ? (
                      tickets
                        .filter((ticket) =>
                          title === ""
                            ? true
                            : ticket.title
                                .toLowerCase()
                                .includes(title.toLowerCase())
                        )
                        .map((ticket) => (
                          <div className="col-md-12 mb-2" key={ticket.id}>
                            <TicketCard
                              props={{
                                id: ticket.id,
                                title: ticket.title,
                                description: ticket.description,
                                agent: ticket.agent
                                  ? ticket.agent.name
                                  : "Unassigned",
                                status: ticket.status,
                                employee: ticket.employee.name,
                                createdAt: ticket.createdAt,
                                priority: ticket.priority,
                                project: ticket.projectModule,
                                type: ticket.type,
                                fetchPreview: navigateToPage,
                              }}
                            />
                          </div>
                        ))
                    ) : (
                      // No tickets to display
                      <p className="text-center f-12">No Ticket Found</p>
                    )
                  ) : (
                    // Display loader while tickets are being fetched
                    <div className="col-md-12 text-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
            <div className="card p-3"></div>
          </div> */}
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
