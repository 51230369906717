import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import TicketCard from "../components/TicketCard";
import Loader from "../components/Loader";
import { fetchTicket, fetchTicketMessages } from "../config/webclient";
import TicketMessageLog from "../components/TicketMessageLog";

import { useDispatch, useSelector } from "react-redux";
import { fetchTicketsForRole } from "../tickets/ticketsSlice";

export default function Tickets() {
  const [createdAtFilter, setCreatedAtFilter] = useState("All");


  const [priorityFilter, setPriorityFilter] = useState("Any");
  const [statusFilter, setStatusFilter] = useState("Any");
  const [typeFilter, setTypeFilter] = useState("Any");
  const [sortOrder, setSortOrder] = useState("Date Created");
  const [layout, setLayout] = useState(
    localStorage.getItem("layout") || "list"
  );

  const role = localStorage.getItem("role");
  const companyId = localStorage.getItem("id");


  const dispatch = useDispatch();
  const { tickets, ticketsLoaded } = useSelector(
    (state) => state.tickets
  );
  
  useEffect(() => {
    if (role && companyId) {
      dispatch(fetchTicketsForRole({ id: companyId, role }));
    }
  }, [dispatch, role, companyId]);

  const sortTickets = (tickets) => {
    return tickets.sort((a, b) => {
      switch (sortOrder) {
        case "Ascending":
          return a.title.localeCompare(b.title);
        case "Descending":
          return b.title.localeCompare(a.title);
        case "Date Created":
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return tickets;
      }
    });
  };

  const filterTickets = (tickets) => {
    return tickets.filter((ticket) => {
      const ticketDate = new Date(ticket.createdAt);
      const now = new Date();

      let createdAtCondition = true;
      let priorityCondition = true;
      let statusCondition = true;
      let typeCondition = true;

      switch (createdAtFilter) {
        case "Within 5 minutes":
          createdAtCondition = now - ticketDate <= 5 * 60 * 1000;
          break;
        case "Within 15 minutes":
          createdAtCondition = now - ticketDate <= 15 * 60 * 1000;
          break;
        case "Within 30 minutes":
          createdAtCondition = now - ticketDate <= 30 * 60 * 1000;
          break;
        case "Within 1 hour":
          createdAtCondition = now - ticketDate <= 60 * 60 * 1000;
          break;
        case "Within 4 hours":
          createdAtCondition = now - ticketDate <= 4 * 60 * 1000;
          break;
        case "Within 12 hours":
          createdAtCondition = now - ticketDate <= 12 * 60 * 60 * 1000;
          break;
        case "Within 24 hours":
          createdAtCondition = now - ticketDate <= 24 * 60 * 60 * 1000;
          break;
        case "Today":
          createdAtCondition = ticketDate.toDateString() === now.toDateString();
          break;
        case "Yesterday":
          const yesterday = new Date(now);
          yesterday.setDate(yesterday.getDate() - 1);
          createdAtCondition =
            ticketDate.toDateString() === yesterday.toDateString();
          break;
        case "This Week":
          const weekStart = new Date(now.setDate(now.getDate() - now.getDay()));
          createdAtCondition = ticketDate >= weekStart;
          break;
        case "Last 7 days":
          const last7Days = new Date(now);
          last7Days.setDate(now.getDate() - 7);
          createdAtCondition = ticketDate >= last7Days;
          break;
        case "This month":
          const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
          createdAtCondition = ticketDate >= monthStart;
          break;
        default:
          createdAtCondition = true;
      }

      if (priorityFilter !== "Any") {
        priorityCondition = ticket.priority === priorityFilter;
      }
      if (statusFilter !== "Any") {
        statusCondition = ticket.status === statusFilter;
      }
      if (typeFilter !== "Any") {
        typeCondition = ticket.type === typeFilter;
      }
      return (
        createdAtCondition &&
        priorityCondition &&
        statusCondition &&
        typeCondition
      );
    });
  };

  const sortedAndFilteredTickets = sortTickets(filterTickets(tickets));

  const handleLayoutChange = (e) => {
    const selectedLayout = e.target.value;
    setTicketDetails("");
    setShowTicketPreview(false);
    setLayout(selectedLayout);
    localStorage.setItem("layout", selectedLayout);
  };

  const [ticketDetails, setTicketDetails] = useState({});
  const [showTicketPreview, setShowTicketPreview] = useState(false);
  const [logsLoaded, setLogsLoaded] = useState(false);
  const navigate = useNavigate();

  const [ticketLogs, setTicketLogs] = useState([]);

  const fetchTicketLogs = async (id) => {
    try {
      await fetchTicketMessages(id).then((response) => {
        const logs = response.data;
        setTicketLogs(
          logs.sort((b, a) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setLogsLoaded(true);
      });
    } catch (error) {
      setLogsLoaded(false);
      console.log(error);
    }
  };
  const fetchTicketDetails = async (id) => {
    try {
      await fetchTicket(id).then((response) => {
        setShowTicketPreview(true);
        fetchTicketLogs(id);

        setTicketDetails(response.data);
      });
    } catch (error) {
      setLogsLoaded(false);
      console.log(error);
    }
  };

  const navigateToPage = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }
    navigate(`/${role}/ticket/${id}`);
  };

  const type = [
    { label: "Incident" },
    { label: "Query" },
    { label: "Change Request" },
    { label: "Training" },
    { label: "Project" },
    { label: "Service Request" },
  ];

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const status = searchParams.get("status");
    setStatusFilter(status || "Any");
  }, [searchParams]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setStatusFilter(value);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("status", value);
    window.history.replaceState(null, "", `?${newParams.toString()}`);
  };

  return (
    <div className="ticket-container height-100-hidden">
      <MainLayout>
        <div className="card margin-less-25 no-border">
          <div className="p-2 mb-0 d-flex justify-content-between">
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">
                <b>Sort</b>
              </p>
              <select
                className="form-select f-13"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <option value="Date Created">Date Created</option>
                <option value="Ascending">Ascending</option>
                <option value="Descending">Descending</option>
              </select>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">
                <b>Filters</b>
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 mx-2 f-13">Created</p>
                <select
                  className="my-2 f-13 form-select no-box"
                  value={createdAtFilter}
                  onChange={(e) => setCreatedAtFilter(e.target.value)}
                >
                  <option>All</option>
                  <option>Within 5 minutes</option>
                  <option>Within 15 minutes</option>
                  <option>Within 30 minutes</option>
                  <option>Within 1 hour</option>
                  <option>Within 4 hours</option>
                  <option>Within 12 hours</option>
                  <option>Within 24 hours</option>
                  <option>Today</option>
                  <option>Yesterday</option>
                  <option>This Week</option>
                  <option>Last 7 days</option>
                  <option>This month</option>
                </select>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 mx-2 f-13">Priority</p>

                <select
                  className="my-2 f-13   form-select no-box"
                  value={priorityFilter}
                  onChange={(e) => setPriorityFilter(e.target.value)}
                >
                  <option>Any</option>
                  <option>Low</option>
                  <option>Medium</option>
                  <option>High</option>
                  <option>Urgent</option>
                </select>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 mx-2 f-13">Status</p>

                <select
                  className="my-2 f-13  form-select no-box"
                  value={statusFilter}
                  onChange={handleSelectChange}
                >
                  <option>Any</option>
                  <option value="open">Open</option>
                  <option value="resolved">Resolved</option>
                  <option value="closed">Closed</option>
                  <option value="reopen">Reopen</option>
                  <option value="in-process">In Process</option>
                  <option value="pending">Pending</option>
                  <option value="awaiting on customer">
                    Awaiting on Customer
                  </option>
                  <option value="awaiting on third party">
                    Awaiting on Third Party
                  </option>
                </select>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 mx-2 f-13">Type</p>

                <select
                  className="my-2 f-13  form-select no-box"
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                >
                  <option>Any</option>
                  {type.map((item, index) => (
                    <option key={index}>{item.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">
                <b>Layout</b>
              </p>
              <select
                className="form-select height-fit-content f-13"
                value={layout}
                onChange={handleLayoutChange}
              >
                <option value="list">List View</option>
                <option value="grid">Grid View</option>
              </select>
            </div>
          </div>
        </div>
        <div className="container-fluid height-100">
          <div className="row justify-content-between mr-0">
            <div
              className={`my-2 sticky-height ${
                layout === "grid" ? "row col-md-12" : "col-md-6"
              }`}
            >
            
              {ticketsLoaded ? (
                sortedAndFilteredTickets.length > 0 ? (
                  sortedAndFilteredTickets.map((ticket) => (
                    <div
                      className={
                        layout === "grid" ? "col-md-4" : "col-md-12 mb-2"
                      }
                      id={
                        ticketDetails.id === ticket.id ? "highlightSelected" : ""
                      }
                    >
                      <TicketCard
                        key={ticket.id}
                        props={{
                          id: ticket.id,
                          title: ticket.title,
                          description: ticket.description,
                          agent: ticket.agent
                            ? ticket.agent.name
                            : "Unassigned",
                          status: ticket.status,
                          employee: ticket.employee.name,
                          createdAt: ticket.createdAt,
                          priority: ticket.priority,
                          project: ticket.projectModule,
                          type: ticket.type,
                          fetchPreview: fetchTicketDetails,
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p className="text-grey">No tickets found</p>
                )
              ) : (
                <div className={layout === "grid" ? "col-md-12" : "col-md-12"}>
                  <Loader />
                </div>
              )}
            </div>
            {layout === "list" && showTicketPreview ? (
              <div className="col-md-6 mt-3 sticky-height draggable-width">
                <div className="card p-3">
                  <div>
                    <div>
                      <h5>
                        {ticketDetails.title} #{ticketDetails.id}
                      </h5>
                      <button
                        className="btn btn-primary p-1 f-12 bg-primary px-2"
                        onClick={() => {
                          navigateToPage(ticketDetails.id);
                        }}
                      >
                        Ticket Logs
                      </button>
                      <hr />
                      <p
                        className="mb-0 f-13"
                        dangerouslySetInnerHTML={{
                          __html: ticketDetails.description,
                        }}
                      />
                      <hr />
                      {logsLoaded
                        ? ticketLogs.map((message) => (
                            <TicketMessageLog
                              props={{
                                senderName: message.employee.name,
                                timestamp: message.createdAt,
                                message: message.message,
                                timeStamp: message.createdAt,
                                attachment: message.attachmentUrl,
                              }}
                            />
                          ))
                        : "Loading"}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {layout !== "grid" ? (
                  <div className="col-md-6 text-center d-flex align-items-center justify-content-center">
                    <div className="card p-5 w-100">
                      <i className="fa fa-ticket f-20" />
                      <h5 className="f-20">Select a ticket to preview</h5>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
